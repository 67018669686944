import mime from 'mime-types';

const FileType = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  PDF: 'pdf',
  FILE: 'file',
};

// Gets the mime type and then returns the first part of it (audio, video, application, etc...)
const getFileType = (type) => {
  const mimeType = mime.contentType(type);
  if (mimeType) {
    if (mimeType === 'application/pdf') {
      return FileType.PDF;
    }
    return mimeType.split('/')[0];
  }
  // The mime-types package returns false when the input is not valid
  return false;
};

const getStreamAttachmentData = (fileName, contentType, mediaLink) => {
  const fileType = getFileType(contentType); // image, video, audio, etc...
  switch (fileType) {
    case FileType.IMAGE:
      return {
        title: fileName,
        type: FileType.IMAGE,
        thumb_url: mediaLink,
        asset_url: mediaLink,
      };
    case FileType.VIDEO:
      return {
        title: fileName,
        type: FileType.VIDEO,
        thumb_url: mediaLink,
        asset_url: mediaLink,
      };
    case FileType.AUDIO:
      return {
        type: FileType.AUDIO,
        title: 'Audio Recording',
        asset_url: mediaLink,
      };
    case FileType.PDF:
      return {
        mime_type: contentType,
        title: fileName,
        asset_url: mediaLink,
        type: FileType.FILE,
      };
    default:
      return {
        title: fileName,
        title_link: mediaLink,
        text: fileName,
      };
  }
};

const CHANNEL_QUERY_LIMIT = 30;

const getAllChannels = async (client, coachId, userIds, offset = 0, channelList = []) => {
  const newChannelList = await client.queryChannels({
    type: 'messaging',
    members: { $in: [coachId] },
    $or: [{ hidden: true }, { hidden: false }],
  }, {}, { limit: CHANNEL_QUERY_LIMIT, offset });
  const tempOffset = offset + CHANNEL_QUERY_LIMIT;
  channelList.push(...newChannelList);
  if (newChannelList.length !== 0) {
    await getAllChannels(client, coachId, userIds, tempOffset, channelList);
  }
  const filteredChannelList = channelList.filter((channel) => userIds.includes(channel.id));
  return filteredChannelList;
};

export {
  getAllChannels,
  getStreamAttachmentData,
};
