import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { computed } from 'mobx';
import format from 'string-template';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { Chip } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';

import { MeasurementUnit } from '../../../../../utils/measurement';
import { DateFormat } from '../../../../../utils/date';
import useSessionStore from '../../../../../hooks/useSessionStore';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import { InfoTag } from '../../../../../components/Tags';
import { getUserStatus } from '../../../../utils/userStatus';
import { getCheckInDay } from '../../../../utils/checkIn';
import UserNutritionProfile from '../../../../Model/UserNutritionProfile';
import ChatButton from '../../../../components/ChatButton';
import ClientQuickInfoButton from '../../../../components/ClientQuickInfoButton';
import ManageClientTags from '../../../../components/ManageClientTags';
import ChatContext from '../../../../context/ChatContext';
import OnCallActions from '../../../../../components/OnCallActions';
import DialogRoundedModal from '../../../../../components/DialogRoundedModal';
import { SaveButton, CancelButton } from '../../../../../components/Button/ActionButtons';
import InfoItem from '../InfoItem';
import ContactInfoItem from '../ContactInfoItem';

import {
  Container,
  InfoContainer,
  InfoWrapper,
  Name,
  StyledAvatar,
  ContactInfo,
  StyledEmailIcon,
  StyledPhoneIcon,
  StyledLocationIcon,
  NameContainer,
  TagsSection,
  ChipContainer,
  ActivateUserModalContent,
} from './styles';
import texts from './texts';

const EMPTY_CELL = '-';
const genderLabel = {
  MALE: 'Male',
  FEMALE: 'Female',
};

const UserInfoSection = ({ userDoc }) => {
  const {
    name,
    email,
    address,
    phoneNumber,
    birthdate,
    avatarUrl,
    checkInDay,
    customTags,
  } = userDoc;

  const {
    userId,
    isOnCallUser,
  } = useSessionStore();

  const [userNutritionProfileDoc, setUserNutritionProfileDoc] = useState();
  const [isUserActive, setIsUserActive] = useState(false);
  const [showActiveUserModal, setShowActiveUserModal] = useState(false);
  const { queryUserForActiveness, chatClient } = useContext(ChatContext);

  const isComponentMountedRef = useComponentMounted();

  const handleOnlineStatus = useCallback((event) => {
    if (event.type === 'user.presence.changed') {
      if (event.user && event.user.id === userDoc.id) {
        setIsUserActive(event.user.online);
      }
    }
  }, [
    userDoc.id,
  ]);

  useEffect(() => {
    const userStatusListener = chatClient.on('user.presence.changed', handleOnlineStatus);

    return () => userStatusListener.unsubscribe();
  }, [
    chatClient,
    isUserActive,
    handleOnlineStatus,
  ]);

  useEffect(() => {
    const init = async () => {
      const nutritionProfile = new UserNutritionProfile(userDoc.id);
      await nutritionProfile.init();

      const { user } = await queryUserForActiveness(userDoc.id);
      if (user) {
        setIsUserActive(user.online);
      }
      setUserNutritionProfileDoc(nutritionProfile);
    };

    init();
  }, [
    userDoc.id,
    queryUserForActiveness,
    handleOnlineStatus,
  ]);

  const heightText = useMemo(() => {
    if (!userNutritionProfileDoc) {
      return EMPTY_CELL;
    }

    const { height, heightMeasurementUnit } = userNutritionProfileDoc;

    if (!height) {
      return EMPTY_CELL;
    }

    return heightMeasurementUnit === MeasurementUnit.METRIC
      ? format(texts.heightValues[heightMeasurementUnit], { height })
      : format(texts.heightValues[heightMeasurementUnit], { ft: height.ft, in: height.in });
  }, [
    userNutritionProfileDoc,
  ]);

  const currentWeightText = useMemo(() => {
    if (!userNutritionProfileDoc) {
      return EMPTY_CELL;
    }

    const { currentWeight, weightMeasurementUnit } = userNutritionProfileDoc;

    if (!currentWeight) {
      return EMPTY_CELL;
    }

    return format(texts.weightValues[weightMeasurementUnit], { weight: currentWeight });
  }, [
    userNutritionProfileDoc,
  ]);

  const clientStatus = useMemo(() => computed(() => {
    const { status, associatedDateString } = getUserStatus(userDoc, DateFormat.DEFAULT_DATE_FORMAT);
    let statusText = status;
    if (associatedDateString) {
      statusText += ` (${associatedDateString})`;
    }
    return statusText;
  }), [
    userDoc,
  ]).get();

  const onActivateUser = useCallback(async () => {
    await userDoc.activateUser(userId);

    if (isComponentMountedRef.current) {
      setShowActiveUserModal(false);
    }
  }, [
    userDoc,
    userId,
    isComponentMountedRef,
  ]);

  return (
    <Container>
      <StyledAvatar src={avatarUrl} />
      <InfoContainer>
        <NameContainer>
          <Name>{name}</Name>
          <Chip
            label={isUserActive ? texts.online : texts.offline}
            color={isUserActive ? 'success' : 'default'}
          />
          <ChatButton
            userId={userDoc.id}
            text={texts.chat}
            popperPlacement="right-start"
            smallButton
          />
          <ClientQuickInfoButton userId={userDoc.id} />
        </NameContainer>
        <InfoWrapper>
          <InfoItem label={texts.gender}>
            {(userNutritionProfileDoc && genderLabel[userNutritionProfileDoc?.biologicalSex]) || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.age}>
            {birthdate ? moment().diff(moment(birthdate), 'years') : EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.height}>
            {heightText}
          </InfoItem>
          <InfoItem label={texts.weight}>
            {currentWeightText}
          </InfoItem>
          <InfoItem label={texts.birthdate}>
            {birthdate ? moment(birthdate).utc().format(DateFormat.DEFAULT_DATE_FORMAT) : EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.status}>
            {clientStatus || EMPTY_CELL}
          </InfoItem>
          <InfoItem label={texts.checkInDay}>
            {getCheckInDay(checkInDay)}
          </InfoItem>
        </InfoWrapper>
        <ContactInfo>
          <ContactInfoItem icon={<StyledLocationIcon />}>
            {address || EMPTY_CELL}
          </ContactInfoItem>
          <ContactInfoItem icon={<StyledEmailIcon />}>
            {email || EMPTY_CELL}
          </ContactInfoItem>
          <ContactInfoItem icon={<StyledPhoneIcon />}>
            {phoneNumber || EMPTY_CELL}
          </ContactInfoItem>
        </ContactInfo>
        <TagsSection>
          <ManageClientTags
            selectedClientList={[userDoc.id]}
            assignedTags={customTags}
            buttonLabel={texts.tagClient}
          />
          <ChipContainer>
            {customTags.map((tag) => (
              <InfoTag key={tag}>{tag}</InfoTag>
            ))}
          </ChipContainer>
        </TagsSection>
      </InfoContainer>
      {isOnCallUser && !userDoc.isActive && (
        <>
          <OnCallActions
            actions={[
              {
                onClick: () => setShowActiveUserModal(true),
                disabled: false,
                label: texts.activateUser,
              },
            ]}
          />
          <DialogRoundedModal
            open={showActiveUserModal}
            title={texts.activateUser}
            onClose={() => setShowActiveUserModal(false)}
            actionButtons={(
              <>
                <SaveButton
                  onClick={onActivateUser}
                >
                  {texts.buttons.activate}
                </SaveButton>
                <CancelButton
                  onClick={() => setShowActiveUserModal(false)}
                >
                  {texts.buttons.cancel}
                </CancelButton>
              </>
            )}
          >
            <ActivateUserModalContent>
              {ReactHtmlParser(texts.activateUserDescription)}
            </ActivateUserModalContent>
          </DialogRoundedModal>
        </>
      )}
    </Container>
  );
};

UserInfoSection.propTypes = {
  userDoc: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(UserInfoSection);
