import {
  useContext,
  useCallback,
  useMemo,
} from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/browser';

import FirebaseContext from '../context/FirebaseContext';
import { DateFormat } from '../utils/date';
import {
  storageURLtoBlobURL,
  storageJSONURLtoJSONData,
} from '../utils/dataProcessing';

const StorageProcessState = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  INITIAL: 'INITIAL',
};

const useStorage = () => {
  const {
    firebase: {
      storage,
    },
  } = useContext(FirebaseContext);

  /**
   * Listens and handles state changes for a Firebase upload task.
   * @param {firebase.storage.UploadTask} uploadTask Firebase storage upload task
   * @param {String} dataUrl Url representation of the file to upload
   * @param {String} storageRef Firebase storage reference of the file to upload
   * @param {String} errorMessage Sentry extra error message
   * @return {Promise<Object>} Promise that resolves to an object containing the storage task state and, if successful,
   * the file's storage reference
   */
  const attachListenerToStorageTask = useCallback(({
    uploadTask,
    dataUrl,
    storageRef,
    errorMessage,
  }) => new Promise((resolve) => {
    uploadTask.on(
      'state_changed',
      null,
      (error) => {
        Sentry.captureException(error, {
          extra: {
            message: errorMessage,
            fileRef: storageRef,
          },
        });
        URL.revokeObjectURL(dataUrl);
        resolve({
          state: StorageProcessState.FAILED,
        });
      },
      async () => {
        const publicUrl = await uploadTask.snapshot.ref.getDownloadURL();
        URL.revokeObjectURL(dataUrl);
        resolve({
          state: StorageProcessState.SUCCESS,
          fileRef: storageRef,
          publicUrl,
        });
      },
    );
  }), []);

  /**
   * Uploads a JSON string to Firebase storage
   * @param {String} jsonString The JSON data as a string
   * @param {String} storagePath Firebase storage reference path to upload the file
   * @param {String} fileName Filename without extension
   * @return {Promise<Object>} Promise that resolves to an object containing the storage task state and, if successful,
   * the file's storage reference
   */
  const uploadJSON = useCallback(async (
    jsonString,
    storagePath,
    fileName,
    useUniqueName = false,
  ) => {
    const rootRef = storage.ref();
    const storageFileName = useUniqueName ? `${fileName}-${uuidv4()}` : fileName;

    const blob = new Blob([jsonString], { type: 'application/json' });
    const storageRef = `${storagePath}/${storageFileName}.json`;

    const uploadTask = rootRef.child(storageRef).put(blob);

    return attachListenerToStorageTask({
      uploadTask,
      dataUrl: null, // No data URL here since it's just a JSON string, not an image or other binary data.
      storageRef,
    });
  }, [
    storage,
    attachListenerToStorageTask,
  ]);

  /**
   * Uploads a file to Firebase storage
   * @param {String} dataUrl Url representation of the file to upload
   * @param {String} fileFormat Format of the file to upload
   * @param {String} storageRef Firebase storage reference of the file to upload
   * @param {String} fileName optional filename
   * @param {String} fileType optional file mime type
   * @return {Promise<Object>} Promise that resolves to an object containing the storage task state and, if successful,
   * the file's storage reference
   */
  const uploadAttachment = useCallback(async (
    dataUrl,
    fileFormat,
    storagePath,
    fileName = '',
    fileType = '',
  ) => {
    const rootRef = storage.ref();
    const storageFileName = fileName
      ? `${fileName}-${uuidv4()}.${fileFormat}`
      : `${moment().format(DateFormat.DEFAULT_DATE_WITH_TIME_FORMAT)}-${uuidv4()}.${fileFormat}`;
    const storageRef = `${storagePath}/${storageFileName}`;

    const fileDataBlob = await fetch(dataUrl)
      .then((resp) => resp.blob());

    const uploadTask = rootRef.child(storageRef).put(fileDataBlob, { contentType: fileType || fileFormat });

    return attachListenerToStorageTask({
      uploadTask,
      dataUrl,
      storageRef,
    });
  }, [
    storage,
    attachListenerToStorageTask,
  ]);

  /**
   * Uploads multiple files to Firebase storage
   * @param {Object[]} filesToAdd Array of objects containing files info
   * @param {String} storagePath Firebase storage path were the files will be uploaded
   * @return {Promise<Object[]>} Promise that resolves into an array of objects with the upload results for all files
   */
  const uploadAttachments = useCallback((
    filesToAdd,
    storagePath,
  ) => Promise.all(filesToAdd.map((fileDetail) => {
    const {
      dataUrl,
      format: fileFormat,
      fileName,
      fileType,
    } = fileDetail;

    return uploadAttachment(
      dataUrl,
      fileFormat,
      storagePath,
      fileName,
      fileType,
    );
  })), [
    uploadAttachment,
  ]);

  /**
   * Deletes a file from Firebase storage
   * @param {String} storagePath Firebase storage path of the file to delete
   * @return {{state: String, fileRef: String}} Object that contains the result of the operation and it's file path
   */
  const deleteAttachment = useCallback(async (storagePath) => {
    try {
      await storage
        .ref()
        .child(storagePath)
        .delete();
      return {
        state: StorageProcessState.SUCCESS,
        fileRef: storagePath,
      };
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: 'Error occurred while deleting a file from storage',
          fileRef: storagePath,
        },
      });
      return {
        state: StorageProcessState.FAILED,
        fileRef: storagePath,
      };
    }
  }, [
    storage,
  ]);

  /**
   * Deletes multiple files from Firebase storage
   * @param {Object[]} filesToDelete Array of objects containing the Firebase storage path of the
   * files to delete
   * @return {Promise<Object[]>} Promise that resolves into an array of objects with the delete results for all files
   */
  const deleteAttachments = useCallback((filesToDelete) => Promise.all(filesToDelete.map((fileDetail) => {
    const { storagePath } = fileDetail;

    return deleteAttachment(storagePath);
  })), [
    deleteAttachment,
  ]);

  /**
   * Downloads a file from Firebase storage and creates an object URL using createObjectURL
   * @param {String} storagePath Firebase storage path of the file to download
   * @return {Promise<String>} Promise that resolves into an object URL string
   */
  const getBlobUrl = useCallback(async (storagePath) => storageURLtoBlobURL(storagePath, storage), [
    storage,
  ]);

  /**
   * Downloads multiple files from Firebase storage and creates Object URLs for every file
   * @param {String[]} storagePaths Array of strings containing the Firebase storage paths
   * @return {Promise<String[]>} Promise that resolves into an array of objects URL strings
   */
  const getBlobUrls = useCallback((storagePaths) => {
    const promises = storagePaths.map(async (storagePath) => ({
      storagePath,
      dataUrl: await getBlobUrl(storagePath),
    }));
    return Promise.all(promises);
  }, [
    getBlobUrl,
  ]);

  /**
   * Downloads a JSON file from Firebase storage and creates a JSON object
   * @param {String} storagePath Firebase storage path of the file to download
   * @return {Promise<Object>} Promise that resolves into the JSON data
   */
  const getJSONData = useCallback(async (storagePath) => storageJSONURLtoJSONData(storagePath, storage), [
    storage,
  ]);

  return useMemo(() => ({
    uploadJSON,
    uploadAttachment,
    uploadAttachments,
    deleteAttachment,
    deleteAttachments,
    getBlobUrl,
    getBlobUrls,
    getJSONData,
  }), [
    uploadJSON,
    uploadAttachment,
    uploadAttachments,
    deleteAttachment,
    deleteAttachments,
    getBlobUrl,
    getBlobUrls,
    getJSONData,
  ]);
};

export default useStorage;
export {
  StorageProcessState,
};
