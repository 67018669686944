import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import moment from 'moment';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { Avatar } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { diffWordsWithSpace } from 'diff';
import { forEach } from 'p-iteration';

import { DateFormat } from '../../../../../../utils/date';
import useSessionStore from '../../../../../../hooks/useSessionStore';
import useComponentMounted from '../../../../../../hooks/useComponentMounted';
import FirebaseContext from '../../../../../../context/FirebaseContext';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import ChatContext from '../../../../../context/ChatContext';
import CheckinFeedback from '../../../../../Model/CheckinFeedback';
import FeedContext from '../../../../../context/FeedContext';
import useUpdateInteraction from '../../../../../hooks/useUpdateInteraction';
import useToast from '../../../../../hooks/useToast';
import { getStreamAttachmentData } from '../../../../../utils/stream';
import { MAX_FILE_UPLOAD_SIZE } from '../../../../Chat/util/fileUpload';
import useLogger from '../../../../../../hooks/useLogger';
import { CoachingActivity } from '../../../../../../utils/log';
import {
  StyledButton,
  StyledTextArea,
  FeedbackContainer,
  StyledChatIcon,
  StyledInfoText,
  StyledGenerateButton,
  StyledGenerateIcon,
  HeaderContainer,
  HeaderTime,
  HeaderTitle,
  NameContainer,
  Container,
  StyledIconButton,
  StyledSpinner,
  SmartResponseTitle,
  StyledS2Icon,
  FeedbackDiffContainer,
  FeedbackDiffText,
  StyledDiffButton,
  ActionsContainer,
  StyledAttachmentsContainer,
} from './styles';
import texts from './texts.json';

const MILLISECONDS_IN_SECOND = 1000;

const CheckInFeedback = () => {
  const [feedbackText, setFeedbackText] = useState('');
  const [currentCheckInFeedbackDoc, setCurrentCheckInFeedbackDoc] = useState({});
  const [showFeedbackDifferences, setShowFeedbackDifferences] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { sendMessage, chatClient } = useContext(ChatContext);
  const { firebase: { remote } } = useContext(FirebaseContext);
  const {
    onMarkAsRead,
    selectedActivity: activity,
    setSelectedActionBarType,
    isSmartFeedbackSelected,
  } = useContext(FeedContext);
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();
  const {
    authUser: {
      uid: authUserId,
    } = {},
  } = useSessionStore();
  const { updateLastInteraction } = useUpdateInteraction(activity.user);

  const [fileAttachments, setFileAttachments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const { logCoachingActivity } = useLogger();

  const {
    time,
    userName,
    userAvatar,
  } = activity;

  // save time spent on each entry to this component + total time spent per check-in
  useEffect(() => {
    const now = new Date();
    const startTime = now.getTime();
    const entry = {
      enteredAt: now,
    };
    return () => {
      const timeSpent = Date.now() - startTime;
      entry.timeSpent = timeSpent / MILLISECONDS_IN_SECOND;
      if (currentCheckInFeedbackDoc instanceof CheckinFeedback) {
        currentCheckInFeedbackDoc.update({
          entriesAt: [
            ...currentCheckInFeedbackDoc.entriesAt,
            entry,
          ],
          totalTimeSpent: currentCheckInFeedbackDoc.totalTimeSpent + entry.timeSpent,
        });
      }
    };
  }, [
    currentCheckInFeedbackDoc,
  ]);

  const activityTime = useMemo(() => (
    moment.utc(time?.toDate()).local().fromNow()
  ), [
    time,
  ]);

  // Load the feedback doc for the current check-in activity
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      setShowFeedbackDifferences(false);
      setFeedbackText('');
      setCurrentCheckInFeedbackDoc({});
      try {
        if (activity.checkInId) {
          const checkInFeedbackDoc = await CheckinFeedback.getById(activity.checkInId);
          if (checkInFeedbackDoc && isComponentMountedRef.current) {
            setCurrentCheckInFeedbackDoc(checkInFeedbackDoc);
            if (isSmartFeedbackSelected) {
              setFeedbackText(checkInFeedbackDoc.feedbackText);
            } else {
              setFeedbackText(checkInFeedbackDoc.customFeedbackText || '');
            }
          }
        }
      } catch (error) {
        showToast(`${texts.feedbackError}: ${error}`, {
          error: true,
        });
      }
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    };
    init();
  }, [
    activity.checkInId,
    showToast,
    isComponentMountedRef,
    isSmartFeedbackSelected,
  ]);

  // update feedback text when the feedback doc text changes
  // this can only happen in the case of a regenerated feedback
  useEffect(() => {
    if (isSmartFeedbackSelected && currentCheckInFeedbackDoc?.feedbackText) {
      setFeedbackText(currentCheckInFeedbackDoc.feedbackText);
    } else if (currentCheckInFeedbackDoc?.customFeedbackText) {
      setFeedbackText(currentCheckInFeedbackDoc.customFeedbackText);
    }
  }, [
    currentCheckInFeedbackDoc,
    currentCheckInFeedbackDoc.feedbackText,
    currentCheckInFeedbackDoc.customFeedbackText,
    isSmartFeedbackSelected,
  ]);

  const onFeedbackRegenerate = useCallback(async () => {
    setIsLoading(true);
    try {
      const apiResponse = await remote('aiFeedbackGeneration', {
        checkInId: activity.checkInId,
      });
      if (!apiResponse.ok) {
        throw new Error(apiResponse.statusText);
      }
      const responseToJson = await apiResponse.json();
      const { aiFeedbackText } = responseToJson;
      // we need to save regenrated feedback info to the checkin feedback doc + analytics
      if (aiFeedbackText && currentCheckInFeedbackDoc instanceof CheckinFeedback) {
        await currentCheckInFeedbackDoc.update({
          aiFeedbackText,
          feedbackText: aiFeedbackText,
          regeneratedCount: currentCheckInFeedbackDoc.regeneratedCount + 1,
          lastFeedbackGeneratedAt: new Date(),
        });
      }
    } catch (error) {
      showToast(`${texts.generateError}: ${error.message}`, {
        error: true,
      });
    }
    setIsLoading(false);
  }, [
    remote,
    activity.checkInId,
    showToast,
    currentCheckInFeedbackDoc,
  ]);

  // send coach feedback to client via in-app chat
  const sendFeedback = useCallback(async () => {
    setIsLoading(true);
    try {
      setIsUploading(true);
      const userChannel = await chatClient.channel('messaging', activity.user);
      const attachments = [];
      // upload attachments to steam cdn and prepare attachment data
      await forEach(fileAttachments, async (file) => {
        const { file: fileUrl } = await userChannel.sendFile(file);
        attachments.push(getStreamAttachmentData(file.name, file.type, fileUrl));
      });
      setIsUploading(false);
      await sendMessage(activity.user, format(texts.feedbackMessage, {
        date: moment(activity.time?.toDate()).format(DateFormat.DEFAULT_DATE_FORMAT),
        feedbackText,
      }), { attachments });
      await onMarkAsRead(activity);
      // save final feedback CA submitted along with timestamp
      if (currentCheckInFeedbackDoc instanceof CheckinFeedback) {
        const updateData = {
          submittedAt: new Date(),
          submittedBy: authUserId,
        };
        if (isSmartFeedbackSelected) {
          updateData.feedbackText = feedbackText;
          updateData.isSmartFeedbackSelected = true;
        } else {
          updateData.customFeedbackText = feedbackText;
        }
        await currentCheckInFeedbackDoc.update({ updateData });
        // Update user's last interaction with the logged in user info
        await updateLastInteraction();
      }
      showToast(texts.feedbackSuccess);
      logCoachingActivity(CoachingActivity.CHECKIN_FEEDBACK_SENT, {
        checkinId: activity.checkInId,
        checkinSubmittedAt: activity.checkInData.current.submittedAt.toDate(),
        clientId: activity.user,
      });
    } catch (error) {
      showToast(texts.feedbackError, {
        error: true,
      });
    }
    setIsLoading(false);
    setSelectedActionBarType(null);
  }, [
    feedbackText,
    activity,
    sendMessage,
    onMarkAsRead,
    showToast,
    currentCheckInFeedbackDoc,
    setSelectedActionBarType,
    authUserId,
    updateLastInteraction,
    chatClient,
    setIsUploading,
    fileAttachments,
    isSmartFeedbackSelected,
    logCoachingActivity,
  ]);

  const handleOnBlur = useCallback(async () => {
    if (currentCheckInFeedbackDoc instanceof CheckinFeedback) {
      if (isSmartFeedbackSelected) {
        await currentCheckInFeedbackDoc.update({
          feedbackText,
        });
      } else {
        await currentCheckInFeedbackDoc.update({
          customFeedbackText: feedbackText,
        });
      }
    }
  }, [
    currentCheckInFeedbackDoc,
    feedbackText,
    isSmartFeedbackSelected,
  ]);

  const getDifferences = useCallback(
    (currentText) => diffWordsWithSpace(currentCheckInFeedbackDoc.aiFeedbackText || '', currentText).filter(
      ({ removed }) => !removed,
    ),
    [
      currentCheckInFeedbackDoc.aiFeedbackText,
    ],
  );

  const onFilesSelected = useCallback((event) => {
    const { files } = event.target;
    if (files.length) {
      const filesArray = [...files];
      // Stream supports sending files of any type, just check file size
      const validFiles = filesArray.every((file) => file.size < MAX_FILE_UPLOAD_SIZE);
      if (validFiles) {
        setFileAttachments((prev) => [...prev, ...filesArray]);
      } else {
        showToast(format(texts.fileSizeError, {
          maxFileSize: MAX_FILE_UPLOAD_SIZE / 1000000, // Bytes to MB
        }), { error: true });
      }
    }
  }, [
    showToast,
    setFileAttachments,
  ]);

  const removeFileByIndex = useCallback((index) => {
    const resultAttachments = [...fileAttachments];
    resultAttachments.splice(index, 1);
    setFileAttachments(resultAttachments);
  }, [
    fileAttachments,
    setFileAttachments,
  ]);

  return (
    <FeedbackContainer>
      <HeaderContainer>
        {userAvatar ? <Avatar src={userAvatar} /> : (<Avatar>{userName?.substring(0, 1)}</Avatar>)}
        <NameContainer>
          <Container>
            <HeaderTitle>{userName}</HeaderTitle>
          </Container>
          <HeaderTime>{`${activityTime}`}</HeaderTime>
        </NameContainer>
        <StyledIconButton onClick={() => setSelectedActionBarType(null)}>
          <CloseOutlined />
        </StyledIconButton>
      </HeaderContainer>
      {isSmartFeedbackSelected && (
        <ActionsContainer>
          <SmartResponseTitle>
            <StyledS2Icon />
            {texts.title}
          </SmartResponseTitle>
          <StyledDiffButton
            onClick={() => setShowFeedbackDifferences(!showFeedbackDifferences)}
            disabled={feedbackText === currentCheckInFeedbackDoc.aiFeedbackText}
          >
            {showFeedbackDifferences ? texts.hideDiff : texts.showDiff}
          </StyledDiffButton>
        </ActionsContainer>
      )}
      {
        showFeedbackDifferences
          ? (
            <FeedbackDiffContainer>
              {getDifferences(feedbackText).map((part) => (
                <FeedbackDiffText key={part} highlight={!!part.added}>
                  {part.value}
                </FeedbackDiffText>
              ))}
            </FeedbackDiffContainer>
          )
          : (
            <StyledTextArea
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              onBlur={handleOnBlur}
              minRows={10}
              maxRows={20}
              disabled={isSmartFeedbackSelected && !currentCheckInFeedbackDoc.feedbackText}
              placeholder={(isSmartFeedbackSelected && !currentCheckInFeedbackDoc.feedbackText)
                ? texts.generatingResponse
                : texts.feedbackPlaceholder}
              multiline
              variant="outlined"
              InputProps={{
                endAdornment: (!isSmartFeedbackSelected || currentCheckInFeedbackDoc.feedbackText)
                  ? (
                    <StyledGenerateButton
                      onClick={onFeedbackRegenerate}
                    >
                      <StyledGenerateIcon />
                      {texts.newResponse}
                    </StyledGenerateButton>
                  ) : <StyledSpinner />,
              }}
            />
          )
      }
      <StyledAttachmentsContainer
        fileAttachments={fileAttachments}
        onFilesSelected={onFilesSelected}
        onFileRemoved={removeFileByIndex}
        isUploading={isUploading}
      />
      {isSmartFeedbackSelected && (
        <StyledInfoText>
          {texts.disclaimer}
        </StyledInfoText>
      )}
      <StyledButton
        onClick={sendFeedback}
        disabled={!feedbackText || (isSmartFeedbackSelected && !currentCheckInFeedbackDoc.feedbackText)}
      >
        <StyledChatIcon />
        {texts.sendFeedBack}
      </StyledButton>
      <LoadingOverlay isLoading={isLoading} />
    </FeedbackContainer>
  );
};

export default compose(
  observer,
)(CheckInFeedback);
