import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';

import { Variant } from '../CurrencySelector';
import { getCurrencySymbol } from '../../../../utils/formatters';
import { DEFAULT_CURRENCY } from '../../../../utils/currency';
import {
  FormField,
  StyledCurrencySelector,
  StyledInput,
  StyledCurrencySymbol,
} from './styles';
import texts from './texts.json';

const CURRENCY_KEY = 'currency';

const PriceSelector = ({
  name,
  currency,
  supportedCurrencies,
  value,
  fieldText,
  isReadOnly,
  isCurrencyReadOnly,
  onChange,
  errorMessage,
  className,
}) => {
  const processCurrencyChange = (event) => onChange({
    target: {
      name: CURRENCY_KEY,
      value: event.target.value,
    },
  });

  const renderCurrencySymbol = (currencyCode) => (
    <StyledCurrencySymbol>{getCurrencySymbol(currencyCode)}</StyledCurrencySymbol>
  );

  const renderCurrencySelector = () => (
    <StyledCurrencySelector
      value={currency}
      onChange={processCurrencyChange}
      disabled={isReadOnly}
      supportedCurrencies={supportedCurrencies}
      variant={Variant.SYMBOL_ONLY}
    />
  );

  const getCommonInputProps = (params = {}) => ({
    ...params,
    name,
    value,
    type: 'number',
    label: fieldText,
    disabled: isReadOnly,
    error: value < 0 || !!errorMessage,
    helperText: value < 0 ? texts.errors.positiveNumber : errorMessage,
    InputProps: {
      ...params.InputProps,
      className: '',
      startAdornment: (
        <InputAdornment position="start">
          {(isReadOnly || isCurrencyReadOnly) ? renderCurrencySymbol(currency) : renderCurrencySelector()}
        </InputAdornment>
      ),
    },
  });

  return (
    <FormField className={className}>
      <StyledInput
        {...getCommonInputProps()}
        onChange={onChange}
        id={`${name}-input`}
      />
    </FormField>
  );
};

PriceSelector.propTypes = {
  name: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  fieldText: PropTypes.string,
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func,
  supportedCurrencies: PropTypes.arrayOf(PropTypes.string),
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  isCurrencyReadOnly: PropTypes.bool,
};

PriceSelector.defaultProps = {
  fieldText: '',
  isReadOnly: false,
  supportedCurrencies: [DEFAULT_CURRENCY.code],
  onChange: () => {},
  errorMessage: '',
  className: '',
  isCurrencyReadOnly: false,
};

export default PriceSelector;
